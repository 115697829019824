<template>
	<v-app>
		<student-header />
		<student-default-view />
		<student-footer />
	</v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	name: "StudentLayout",

	components: {
		StudentDefaultView: () => import("./StudentDefaultView"),

		StudentHeader: () => import("./StudentHeader"),

		StudentFooter: () => import("./StudentFooter")
	},
	computed: {
		...mapGetters(["isThemeFetched", "organization", "userId"])
		// organizationId() {
		// 	if (this.$route.query.organizationId !== undefined) {
		// 		return this.$route.query.organizationId;
		// 	} else if (this.organization?.id !== undefined) {
		// 		return this.organization.id;
		// 	} else if (localStorage.getItem("organizationId")) {
		// 		return localStorage.getItem("organizationId");
		// 	} else {
		// 		return 1;
		// 	}
		// }
	},
	methods: {
		...mapActions([
			"getOrganizationInfo",
			"getCoursesTypes",
			"getCourses",
			"getDeletedCourses",
			"getQuizzes",
			"getStartedCourses",
			"getStudentAnalytics"
		])
	},
	created() {
		this.getOrganizationInfo();
		this.getCoursesTypes();
		this.getCourses();
		this.getQuizzes();
		this.getStartedCourses();
		this.getStudentAnalytics(
			`?filters=user_id&filter_values=${this.userId}`
		);
	}
	// async beforeMount() {
	// 	localStorage.setItem("organizationId", this.organizationId);
	// 	await this.$store.dispatch("getOrganizationInfo", this.organizationId);
	// 	await this.$store.dispatch("setThemeColors");

	// 	this.$store.dispatch("setIsThemeFetched", true);
	// }
};
</script>
